@import '../../styles/partials/variables';

@import '../../styles/partials/fonts';

.home {
    height: 100vh;
}
.contact {
    color: white;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;

    @keyframes fadeInOpacity {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

    &__image {
        @include mobile {
            width: 90%;
            margin-left: 3vw;
        }
    }

    &__header {
        @include mobile {
            font-family: 'Aileron UltraLight';
            font-size: 40px;
            display: flex;
            justify-content: center;
            margin-top: 2rem;
        }
        @include tablet {
            font-size: 80px;
        }
        @include desktop {
            font-size: 120px;
        }
    }

    &__message {
        @include mobile {
            display: flex;
            justify-content: center;
            font-size: 18px;
            margin-top: -1.5rem;
            font-family: 'Aileron Thin';
        }
        @include tablet {
            margin-top: -3rem;
            font-size: 25px;
        }
        @include desktop {
            margin-top: -5rem;
        }
    }

    &__sub-heading {
        @include mobile {
            margin: auto;
            display: flex;
            justify-content: center;
            font-size: 15px;
            font-family: 'Aileron Thin';
        }
        @include tablet {
            font-size: 20px;
        }
    }

    &__info {
        @include mobile {
            margin: auto;
            display: flex;
            justify-content: center;
            font-family: 'Aileron Regular';
            font-size: 20px;
            padding: 2rem 0;
            color: #F9A44D;
            
        }
        @include tablet {
            font-size: 25px;
        }
    }

        &__credit {
            text-align: center;
            font-size: 12px;
            padding-top: 1rem;
            width: 250px;
            margin: auto;
            padding-bottom: 2rem;
        }

        &__credit span {
            font-weight: bold;
        }

        &__link {
            text-decoration: none;
            color: white;
        }
     }