// media queries

@mixin mobile {
    @media only screen and (min-width: 320px)  {
        @content;
    }
}

@mixin mobile-medium {
    @media only screen and (min-width: 375px) {
        @content;
    }
}

@mixin mobile-large {
    @media only screen and (min-width: 425px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin large-tablet {
    @media only screen and (min-width: 870px) {
        @content;
    }
}


@mixin desktop {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}