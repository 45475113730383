@import '../../styles/partials/variables';

@import '../../styles/partials/fonts';

html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.header {
    font-family: 'Aileron Regular';
    font-size: 20px;

    &__logo {
        @include mobile {
            padding-left: 5vw;
            color: white; 
        }
    }

    &__nav-list {
        @include mobile {
            padding-left: 5vw;
            cursor: pointer;
        }
        @include tablet {
            display: flex;
            justify-content: flex-end;
            margin-top: -2.5rem;
            padding-right: 5vw;
        }
    }

    &__nav-list-item {
        @include mobile {
            list-style-type: none;
            padding-bottom: 5px;
            color: white; 
        }
        @include tablet {
            padding-left: 2vw;
        }
    }

    &__nav-list-item:hover {
        font-weight: bold;
    }
}