@import "../../styles/partials/variables";

@import "../../styles/partials/fonts";

.footer {
  color: white;

  &__container {
    @include mobile {
      display: flex;
      margin-top: 1rem 0 0 0;
      justify-content: center;
    }
  }

  &__github,
  &__linkedin,
  &__gmail,
  &__gitlab {
    @include mobile {
      padding-right: 5vw;
      font-size: 20px;
      color: white;
    }
  }
}
