@import '../../styles/partials/variables';

@import '../../styles/partials/fonts';

html, body {
    margin: 0;
    padding: 0;
}

.about { 
    color: white;

    &__image,
    &__button {
        @include mobile {
        display: flex;
        justify-content: center;
        margin: auto;
      }
    }
    
    &__image-container {
        @include mobile {
        width: 100%;
        height: 100vh;
        opacity: 1;
	      animation-name: fadeInOpacity;
	      animation-iteration-count: 1;
	      animation-timing-function: ease-in;
	      animation-duration: 1s;
      }
    }

    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &__text-container {
        @include mobile {
        width: 100%;
        height: 50vh;
      }
      @include tablet {
        padding-bottom: 5rem;
      }
      @include desktop {
        padding-top: 5rem;
      }
    }

    &__text {
        @include mobile {
        font-size: 15px;
        font-family: 'Aileron Regular';
        text-align: center;
        margin: 0 5vw;
      }
        @include tablet {
        font-size: 25px;
        width: 70%;
      }
        @include desktop {
        width: 80%;
        font-size: 30px;
      }
    }

    &__header {
        @include mobile {
        font-family: 'Aileron UltraLight';
        font-size: 50px;
      }
        @include tablet {
        font-size: 60px;
      }
        @include desktop {
        font-size: 80px;
      }
    }

    &__header,
    &__text{
        @include mobile {
        display: flex;
        justify-content: center;
      }
    }

    &__text {
      @include tablet {
        padding-bottom: 5rem;
      }
    }

    &__text-box {
        @include mobile {
        display: flex;
        justify-content: center;
        margin: auto;
      }
    }

    &__skills {
        @include mobile {
        margin: -12rem 0 0 5vw;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
        @include tablet {
        margin-left: 2vw;
        margin-top: -15rem;
      }
        @include desktop {
        margin: -20rem 0 0 0;
        padding-top: 6rem;
      }
    }

    &__flex {
      @include mobile {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      }
      @include tablet {
        width: 50%;
      }
      @include desktop {
        width: 40%;
      }
    }

    &__skills-icon {
        @include mobile {
        font-size: 30px;
        padding-right: 20px;
      }
        @include tablet {
        font-size: 40px;
      }
        @include desktop {
        font-size: 60px;
      }
    }

    &__scroll {
        @include mobile {
        display: flex;
        justify-content: center;
        margin: auto;
      }
        @include large-tablet {
        justify-content: flex-end;
        margin: -20rem 11.5vw 0 0;
      }
        @include desktop {
        margin-top: -22rem;
      }
    }

    &__arrow {
        @include mobile {
        width: 20%;
      }
        @include mobile-medium {
        width: 15%;
      }
        @include tablet {
        width: 10%;
        margin-top: -3rem;
      }
    }

    &__scroll-top {
        @include mobile {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 20%;
        padding-bottom: 2rem;
        padding-top: 5rem;
      }
        @include mobile-medium {
        width: 15%;
      }
        @include tablet {
        width: 10%;
      }
        @include desktop {
        width: 8%;
      }
   }
}

