@font-face {
    font-family: 'Aileron Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./../fonts/Aileron-Regular.woff') format('woff');
    }

@font-face {
    font-family: 'Aileron Thin';
    font-style: normal;
    font-weight: normal;
    src: url('./../fonts/Aileron-Thin.woff') format('woff');
    }

 @font-face {
    font-family: 'Aileron Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./../fonts/Aileron-Bold.woff') format('woff');
    }

@font-face {
    font-family: 'Aileron Black';
    font-style: normal;
    font-weight: normal;
    src: url('./../fonts/Aileron-Black.woff') format('woff');
    }

@font-face {
    font-family: 'Aileron UltraLight';
    font-style: normal;
    font-weight: normal;
    src: url('./../fonts/Aileron-UltraLight.woff') format('woff');
    }