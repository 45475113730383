@import '../../styles/partials/variables';

@import '../../styles/partials/fonts';

.work {
    color: white;

    &__header {
        @include mobile {
           display: flex;
           justify-content: center;
           font-family: 'Aileron UltraLight';
           margin: 2rem 0 2rem 0;
           font-size: 60px;
        }
        @include tablet {
            font-size: 100px;
        }
        @include desktop {
            font-size: 150px;
        }
    }

    &__header,
    &__card-container,
    &__scroll-top {
        opacity: 1;
	    animation-name: fadeInOpacity;
	    animation-iteration-count: 1;
	    animation-timing-function: ease-in;
	    animation-duration: 2s;
    }

    @keyframes fadeInOpacity {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

    &__card-container {
        @include mobile {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }

    &__image-info span {
        @include mobile {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: rgba(29, 106, 154, 0.72);
            color: #fff;
            box-sizing: border-box;
            padding: 10px 20px;
            opacity: 0;
            transition: opacity .2s, visibility .2s;
        }
        @include tablet {
            width: 50%;
            margin: auto;
        }
    }

    &__title {
        @include mobile {
            margin: 0;
            padding: 0;
            font-family: 'Aileron Bold';
            font-size: 26px;
        }
        @include tablet {
            font-size: 40px;
        }
        @include desktop {
            font-size: 60px;
        }
    }

    &__link-tag {
        @include mobile {
            text-decoration: none;
            color: white;
        }
    }

    &__description,
    &__year,
    &__link {
        @include mobile {
            font-size: 15px;
            font-family: 'Aileron Regular';
            margin: 4px 0 14px;
        }
        @include desktop {
            font-size: 20px;
        }
    }

    &__image-info:hover span,
    &__image-info:focus span,
    &__image-info:active span {
        opacity: 1;
    }

    &__image {
        @include mobile {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;
        }
        @include tablet {
            width: 50%;
            margin: auto;
            margin-bottom: 4rem;
        }
        @include desktop {
            width: 50%;
            margin-bottom: 6rem;
        }
    }

    &__scroll-top {
        @include mobile {
            margin: auto;
            display: flex;
            justify-content: center;
            width: 20%;
            padding-bottom: 2rem;
            padding-top: 1rem;
        }
        @include mobile-medium {
            width: 15%;
        }
        @include tablet {
            width: 10%;
        }
        @include desktop {
            width: 8%;
        }
    }
}